import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Flex, Heading, Image, Text } from 'rebass/styled-components';
import { navigate } from 'gatsby-link';
import Interweave from 'interweave';
import PropTypes from 'prop-types';
import getDateInFormat from '../utils/getDateInFormat';
import getSingleNewsQuery from '../graphql/queries/getSingleNews.graphql';
import Loader from './Loader';

export default function SingleNewsPage({ slug }) {
  const { data, loading } = useQuery(getSingleNewsQuery, {
    variables: { slug },
  });
  if (loading && !data) {
    return <Loader />;
  }
  if (!slug || (!data && !loading)) {
    navigate('/');
    return null;
  }
  const singleNews = data.getSingleNews;
  return (
    <Flex variant={'wrapper'} flexDirection={'column'}>
      <Box width={[1 / 2, 1 / 3, 1 / 3]} maxHeight={'320px'}>
        <Image src={singleNews.imageSrc} />
      </Box>
      <Flex variant={'singlePageContent'}>
        <Box mx={50} my={20}>
          <Heading
            fontFamily={'Oswald'}
            color="text"
            fontWeight={'bold'}
            fontSize={'35px'}
            marginBottom={'10px'}
          >
            {singleNews.title}
          </Heading>
          <Text
            fontFamily={'Open Sans'}
            fontSize={'16px'}
            fontWeight={'lighter'}
            color={'silent'}
          >
            {getDateInFormat(singleNews.createdOn.unix)}
          </Text>
          <Box className={'db-text'}>
            <Interweave content={singleNews.text} />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

SingleNewsPage.propTypes = {
  slug: PropTypes.string,
};

SingleNewsPage.defaultProps = {
  slug: null,
};
