import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import SingleNewsPage from '../../components/SingleNewsPage';

const SingleNewsController = ({ slug }) => {
  return (
    <Layout>
      <SingleNewsPage slug={slug} />
    </Layout>
  );
};

SingleNewsController.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default SingleNewsController;
